import burn from './burn/reducer';
import { updateVersion } from './global/actions';
import lists from './lists/reducer';
import mint from './mint/reducer';
import multicall from './multicall/reducer';
import swap from './swap/reducer';
import transactions from './transactions/reducer';
import user from './user/reducer';
import burnV2 from './burn/v2/reducer';
import mintV2 from './mint/v2/reducer';
import { combineReducers, configureStore } from '@reduxjs/toolkit';
import { useMemo } from 'react';
import { useDispatch } from 'react-redux';
import {
  FLUSH,
  PAUSE,
  PERSIST,
  persistReducer,
  persistStore,
  PURGE,
  REGISTER,
  REHYDRATE,
  createMigrate,
} from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import IndexedDBStorage from 'utils/IndexedDBStorage';
import { env } from '@/env';

const PERSISTED_KEYS: string[] = ['user', 'transactions'];

const migrations = {
  0: (state) => {
    // migration add userPredictionChainlinkChartDisclaimerShow
    return {
      ...state,
      user: {
        ...state?.user,
        userPredictionChainlinkChartDisclaimerShow: true,
      },
    };
  },
  1: (state) => {
    return {
      ...state,
    };
  },
};

const persistConfig = {
  blacklist: ['profile'],
  key: 'primary',
  migrate: createMigrate(migrations, { debug: false }),
  storage,
  version: 1,
  whitelist: PERSISTED_KEYS,
};

const ListsConfig = {
  deserialize: false,
  key: 'lists',
  serialize: false,
  storage: IndexedDBStorage('lists'),
  version: 1,
};

const persistedReducer = persistReducer(
  persistConfig,
  combineReducers({
    burn,
    burnV2,
    lists: persistReducer(ListsConfig, lists),
    mint,
    mintV2,
    multicall,
    swap,
    transactions,
    // Exchange
    user,
  }),
);

// eslint-disable-next-line import/no-mutable-exports
let store: ReturnType<typeof makeStore>;

export function makeStore(preloadedState?) {
  return configureStore({
    // eslint-disable-next-line turbo/no-undeclared-env-vars
    devTools: env.NEXT_PUBLIC_ENVIRONMENT === 'development',
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware({
        serializableCheck: {
          ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
        },
        thunk: true,
      }),
    preloadedState,
    reducer: persistedReducer,
  });
}

export const initializeStore = (preloadedState?) => {
  let _store = store ?? makeStore(preloadedState);

  // After navigating to a page with an initial Redux state, merge that state
  // with the current state in the store, and create a new store
  if (preloadedState && store) {
    _store = makeStore({
      ...store.getState(),
      ...preloadedState,
    });
    // Reset the current store
    store = undefined;
  }

  // For SSG and SSR always create a new store
  if (typeof window === 'undefined') return _store;

  // Create the store once in the client
  if (!store) {
    store = _store;
  }

  return _store;
};

store = initializeStore();

/**
 * @see https://redux-toolkit.js.org/usage/usage-with-typescript#getting-the-dispatch-type
 */
export type AppDispatch = typeof store.dispatch;
export type AppState = ReturnType<typeof store.getState>;
export const useAppDispatch = () => useDispatch<AppDispatch>();

export default store;

export const persistor = persistStore(store, undefined, () => {
  store.dispatch(updateVersion());
});

export function useStore(initialState) {
  return useMemo(() => initializeStore(initialState), [initialState]);
}
