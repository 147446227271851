/* eslint-disable turbo/no-undeclared-env-vars */
import { SupportedChainId } from '@vaporfi/utils';
import Link from 'next/link';
import React, { memo } from 'react';

const Explorer = {
  [SupportedChainId.AVALANCHE]: 'https://snowtrace.io',
  [SupportedChainId.AVALANCHE_TESTNET]: 'https://testnet.snowtrace.io',
  [SupportedChainId.TELOS]: 'https://www.teloscan.io',
  [SupportedChainId.TELOS_TESTNET]: 'https://testnet.teloscan.io',
} as const;

const AddressLink = ({
  address,
  chainId,
  children,
  ...rest
}: Partial<React.ComponentProps<typeof Link>> & {
  address: string;
  chainId?: SupportedChainId;
}) => {
  const _chainId =
    chainId ??
    (process.env.NEXT_PUBLIC_DEFAULT_CHAIN_ID as unknown as SupportedChainId) ??
    SupportedChainId.AVALANCHE_TESTNET;
  return (
    <Link
      {...rest}
      href={`${Explorer[_chainId]}/address/${address}`}
      target="_blank"
      passHref
      rel="noopener noreferrer"
    >
      {children}
    </Link>
  );
};

export default memo(AddressLink);
