import { VariantProps, tv } from 'tailwind-variants';
import { Container, containerStyles } from '@vaporfi/uikit';
import { HTMLProps, Suspense, memo } from 'react';
import Image from 'next/image';
import Link from 'next/link';
import { cn } from '@vaporfi/utils';

const sidebarStyles = tv({
  base: 'max-lg:hidden flex flex-col gap-6 p-8 h-screen overflow-y-auto',
  variants: {
    variant: { ape: 'bg-[#0B0B0B]', candy: 'bg-blue-750 max-w-[368.74px]' },
  },
});

export interface SidebarProps
  extends VariantProps<typeof sidebarStyles>,
    Omit<HTMLProps<HTMLDivElement>, 'color'> {
  logoUrl: string;
}

export const Logo = memo(
  ({ className, logoUrl }: { logoUrl: string; className?: string }) => (
    <Link
      className={containerStyles({
        class: cn('relative h-auto min-h-[143px] w-full', className),
      })}
      href="/"
    >
      <Image fill alt="logo" src={logoUrl} />
    </Link>
  ),
);

export default function Sidebar({
  children,
  className,
  logoUrl,
  variant,
  ...rest
}: SidebarProps) {
  return (
    <nav className={sidebarStyles({ class: className, variant })} {...rest}>
      <Suspense
        fallback={
          <Container className="relative h-auto min-h-[143px] w-full bg-gray-500/30" />
        }
      >
        <Logo logoUrl={logoUrl} />
      </Suspense>
      {children}
    </nav>
  );
}
