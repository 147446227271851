export const Curtis = [
  {
    address: '0xF165a20767997190f5E83e044f02Ae51e66C9962',
    chainId: 33_111,
    decimals: 18,
    logoURI:
      'https://static.vapordex.io/tokens/assets/43114/0xB97EF9Ef8734C71904D8002F8b6Bc66Dd9c48a6E/logo_24.png',
    name: 'USDC',
    symbol: 'USDC',
  },
  {
    address: '0x6e7812D8E37bfd3453Ca73D1B3fDA10F430E875f',
    chainId: 33_111,
    decimals: 18,
    logoURI:
      'https://static.vapordex.io/tokens/assets/40/0x975Ed13fa16857E83e7C493C7741D556eaaD4A3f/logo.png',
    name: 'USDT',
    symbol: 'USDT',
  },
  {
    address: '0xC009a670E2B02e21E7e75AE98e254F467f7ae257',
    chainId: 33_111,
    decimals: 18,
    logoURI:
      'https://static.vapordex.io/8efae81a-b8f1-400a-9c9e-59c4e93a516b.png',
    name: 'Wrapped Ape',
    symbol: 'WAPE',
  },
] as const;
