import { ChainId } from '@vapordex/sdk';
import { createPublicClient, createWalletClient, http } from 'viem';
import { SupportedChainId } from '@config/constants/chains';
import { privateKeyToAccount } from 'viem/accounts';
import { getChainIdCookie } from '@config/constants/networks';
import { env } from '@/env';
import { viemChains } from '@vaporfi/utils';

//30 req/sec, not adding globally, keeping for viem only unless we get private rpc
const RPC_URLS = {
  [ChainId.AVALANCHE]: 'https://avalanche.blockpi.network/v1/rpc/public',
  [ChainId.AVALANCHE_TESTNET]: 'https://rpc.ankr.com/avalanche_fuji',
  [ChainId.CURTIS]: viemChains.curtis.rpcUrls.default.http?.[0],
  [ChainId.SKALE_EUROPA_TESTNET]:
    viemChains.skaleEuropaTestnet.rpcUrls.default.http?.[0],
  [ChainId.TELOS]: 'https://mainnet15.telos.net/evm',
  [ChainId.TELOS_TESTNET]: 'https://testnet.telos.net/evm',
};

export const ANKR_MAINNET_RPC = 'https://rpc.ankr.com/avalanche';

export const getViemClient = (chainId: SupportedChainId, rpcUrl?: string) => {
  return createPublicClient({
    chain: Object.values(viemChains).reduce((acc, curr) => {
      acc[curr?.id] = curr;
      return acc;
    }, {})[chainId],
    transport: http(rpcUrl ?? RPC_URLS[chainId]),
  });
};

export const mockClient = () => {
  const chainId = getChainIdCookie();
  return createWalletClient({
    account: privateKeyToAccount(
      // eslint-disable-next-line turbo/no-undeclared-env-vars
      (env.NEXT_PUBLIC_MOCK_WALLET_KEY as `0x${string}`) ??
        ('0x123456789abcdef123456789abcdef123456789abcdef123456789abcdef1234' as `0x${string}`),
    ),
    transport: http(RPC_URLS[chainId]),
  });
};
