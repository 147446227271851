import '@styles/globals.css';
import '@styles/nprogress.css';
import '@vaporfi/uikit/libs/animate.min.css';

import Head from 'next/head';
import DefaultLayout from '@layout/default';
import RootProvider from '@providers/RootProvider';
import UnsupportedChain from './unsupported-chain';
import { SupportedChainEnvMap } from '@utils/types';
import type { NextPage } from 'next/types';
import { Montserrat } from 'next/font/google';
import { SpeedInsights } from '@vercel/speed-insights/next';
import { env } from '@/env';

const montserrat = Montserrat({
  subsets: ['latin'],
  variable: '--font-montserrat',
});

export type NextPageWithLayout = NextPage & {
  Layout?: React.FC<React.PropsWithChildren<any>>;
  supportedChainId: 'all' | SupportedChainEnvMap;
};

type Props = {
  Component: NextPageWithLayout;
  // locale: string
  // messages: Record<string, string>
};

const MyApp: NextPage<Props> = ({ Component, ...pageProps }) => {
  const environment = env.NEXT_PUBLIC_ENVIRONMENT;
  const supportedChainIds =
    Component.supportedChainId === 'all'
      ? 'all'
      : Component.supportedChainId?.[environment]; // "?" takes care of undefined case

  const Layout = DefaultLayout;

  return (
    <>
      <Head>
        <title>VaporDEX</title>
      </Head>
      <RootProvider>
        <Layout className={montserrat.className}>
          <UnsupportedChain supportedChainIds={supportedChainIds}>
            <Component {...pageProps} />
            <SpeedInsights />
          </UnsupportedChain>
        </Layout>
      </RootProvider>
    </>
  );
};

export default MyApp;
