import { ButtonHTMLAttributes, CSSProperties, forwardRef } from 'react';
import { tv, VariantProps } from 'tailwind-variants';
import { generateOutlineStyles } from './utils';

type ButtonVariants = VariantProps<typeof buttonStyles>;

export interface ButtonProps
  extends Omit<ButtonHTMLAttributes<HTMLButtonElement>, 'size' | 'color'>,
    ButtonVariants {
  css?: CSSProperties;
  disabled?: boolean;
}

const compoundVariants = generateOutlineStyles(
  'border-light',
  'border-turquoise-500',
  'border-blue-500',
  'border-neutral-800',
  'border-red',
  'border-green-500',
  'border-purple-1',
);
export const buttonStyles = tv(
  {
    base: 'box-border flex gap-[10px] items-center justify-center [&>p]:font-semibold transition-all outline-none',
    compoundVariants,
    defaultVariants: { border: 'full', disabled: false },
    variants: {
      border: { 2: 'rounded-[8px]', full: 'rounded-[50px]' },
      color: {
        blue: 'bg-blue-500 [&>p]:text-light',
        dark: 'bg-neutral-800 [&>p]:text-neutral-300',
        gradient: 'border-gradient-stratosphere [&>p]:text-turquoise-500',
        green: 'bg-green [&>p]:text-light',
        light: 'bg-light [&>p]:text-black',
        neutral: 'bg-neutral-800 [&>p]:text-neutral-300',
        purple: 'bg-purple-3 [&>p]:text-white',
        red: 'bg-red [&>p]:text-light',
        transparent: 'bg-transparent [&>p]:text-light',
        turquoise: 'bg-turquoise-500 [&>p]:text-black',
      },
      disabled: {
        false:
          'hover:opacity-[0.65] active:opacity-[0.85] shadow-none active:translate-y-px cursor-pointer',
        true: 'cursor-not-allowed bg-neutral-600 [&>p]:text-neutral-300',
      },
      fullWidth: {
        false: 'w-auto',
        true: 'w-full',
      },
      size: {
        base: '[&>p]:text-base [&>p]:leading-6 h-9 px-4 py-2',
        sm: '[&>p]:text-sm [&>p]:leading-5 h-7 px-3 py-1.5',
        xs: '[&>p]:text-xs [&>p]:leading-5 h-6 px-2 py-1',
      },
      variant: {
        filled: {},
        outline: 'border-solid bg-transparent',
        round: 'rounded-full w-5 h-5 flex items-center justify-center p-0',
        squared: 'rounded-md flex items-center gap-2 py-1.5 px-2 relative',
        tokenSelector:
          'bg-neutral-800 rounded-md flex items-center gap-2 py-1.5 px-2 relative',
      },
    },
  },
  { responsiveVariants: true },
);

const Button = forwardRef<HTMLButtonElement, ButtonProps>(
  (
    {
      border,
      children,
      className,
      color,
      disabled,
      fullWidth,
      size,
      variant,
      ...rest
    },
    ref,
  ) => {
    const isFullWidth = typeof fullWidth === 'boolean' ? fullWidth : undefined;

    const buttonClassName = buttonStyles({
      border,
      class: className,
      color,
      disabled,
      fullWidth: isFullWidth,
      size,
      variant,
    });

    return (
      <button
        className={buttonClassName}
        disabled={disabled}
        ref={ref}
        {...rest}
      >
        {children}
      </button>
    );
  },
);

Button.displayName = 'Button';

export { Button };
